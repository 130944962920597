import React from 'react'
import { Flex, Box, Image, Text, Heading, Container } from '@chakra-ui/react'
import { Link } from 'gatsby'
import SideNav from './SideNav'

const Navbar = () => {
  return (
    <Flex
      bg="#000"
      color="#fff"
      // borderBottom="3px solid #e53e33"
      // position="fixed"
      // width="100%"
    >
      <Container maxW="1200px">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          height={['60px', '60px', '75px']}
        >
          <Flex direction="row" alignItems="center">
            <Image
              src="/images/smaller-size/BookNeeded Logo_twiter-14.png"
              boxSize="50px"
            />
            <Heading as="h4" size="md" fontWeight="600">
              BookNeeded.
            </Heading>
          </Flex>
          <Box display={['none', 'none', 'block', 'block', 'block']}>
            <Flex>
              <Flex
                direction="row"
                mr={3}
                borderBottom="1px solid #000"
                transition="all 0.2s ease-in-out"
                _hover={{ borderBottom: '1px solid #fff' }}
                pb={1}
              >
                <Link to="/">Donate</Link>
              </Flex>
              <Flex
                direction="row"
                borderBottom="1px solid #000"
                transition="all 0.2s ease-in-out"
                _hover={{ borderBottom: '1px solid #fff' }}
                pb={1}
              >
                <Link to="/">About</Link>
              </Flex>
            </Flex>
          </Box>
          <Box display={['block', 'block', 'none', 'none', 'none']}>
            <SideNav />
          </Box>
        </Flex>
      </Container>
    </Flex>
  )
}

export default Navbar
