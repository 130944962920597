import React from 'react'
import { Box, Container, Flex, Link, ListItem, Text } from '@chakra-ui/react'
import {
  AiFillFacebook,
  AiFillInstagram,
  AiOutlineTwitter,
  AiFillLinkedin,
} from 'react-icons/ai'

const Footer = () => {
  return (
    <Box pt="50px" background="brand.black.900">
      <Container maxW="1200px">
        <Box>
          <Flex
            py="6"
            borderTop="1px solid #ccc"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex>
              <Text fontSize="12px" color="grey">
                &copy; 2020 - {new Date().getFullYear()} BookNeeded, Inc, All
                rights reserved
              </Text>
            </Flex>
            <Flex>
              <Box>
                <AiFillLinkedin fontSize="28px" />
              </Box>
              <Box mx="4">
                <AiFillFacebook fontSize="28px" />
              </Box>
              <Box>
                <AiFillInstagram fontSize="28px" />
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
