import React from 'react'
import {
  Flex,
  Box,
  Image,
  Text,
  Heading,
  Button,
  Container,
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import { ArrowForwardIcon } from '@chakra-ui/icons'

const Banner = () => {
  return (
    <Box mb={['40px', '40px', '80px']}>
      <Container maxW="1200px">
        <Flex
          justifyContent="space-between"
          // height={[
          //   'calc(100vh - 63px)',
          //   'calc(100vh - 63px)',
          //   'calc(100vh - 78px)',
          // ]}
          width="90%"
          margin="0 auto"
          flexDirection={['column', 'column', 'row', 'row', 'row']}
        >
          <Box flex="1" mt={['70px', '70px', '150px']}>
            <Text fontSize="35px" fontWeight="500">
              Its time to change the Book Sharing Experience
            </Text>
            <Text mt={5}>
              Purchases and donations both are our part.
              <br /> Lets join the ecosystem.
            </Text>
            <Button
              rightIcon={<ArrowForwardIcon />}
              mt={5}
              colorScheme="red"
              fontWeight="500"
              boxShadow="lg"
            >
              Request
            </Button>
          </Box>
          <Flex flex="1" mt={['0px', '50px']} justifyContent="center">
            <Image src="/images/banner-image.svg" boxSize="500px" />
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default Banner
