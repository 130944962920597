import React, { useState } from 'react'
import {
  Flex,
  Box,
  Image,
  Text,
  Button,
  Center,
  Icon,
  Select,
  Stack,
  Container,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  RadioGroup,
  Radio,
  HStack,
  InputLeftAddon,
  InputGroup,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import { ArrowForwardIcon } from '@chakra-ui/icons'

const DonationForm = () => {
  const { isOpen, onToggle } = useDisclosure()
  const [city, setCity] = useState('')

  return (
    <Box mb={['10px', '20px', '60px', '70px', '70px']}>
      <Container maxW="1200px">
        <Center mb={4}>
          <Text fontSize="25px" fontWeight="600" pb="15px">
            Donate Books
          </Text>
        </Center>
        <Flex
          width="90%"
          margin="0 auto"
          justifyContent="space-between"
          flexDirection={['column', 'column', 'row', 'row', 'row']}
        >
          <Stack
            direction="column"
            flex="0.9"
            boxShadow="xl"
            px={5}
            py={10}
            rounded="lg"
          >
            <FormControl id="email">
              <FormLabel>No of books</FormLabel>
              <Input type="number" focusBorderColor="crimson" />
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            </FormControl>
            <FormControl id="adress">
              <FormLabel>Current city</FormLabel>
              <Select
                placeholder="Select city"
                focusBorderColor="crimson"
                onChange={e => {
                  setCity(e.target.value)
                  // onToggle()
                }}
              >
                <option>Islamabad</option>
                <option>Rawalpindi</option>
                <option>Karachi</option>
                <option>Lahore</option>
              </Select>
            </FormControl>
            {(city == 'Islamabad' || city == 'Rawalpindi') && (
              // <Collapse in={isOpen} animateOpacity>
              <FormControl id="adress">
                <FormLabel>Books(s) Pickup address</FormLabel>
                <Input type="text" focusBorderColor="crimson" />
                {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
              </FormControl>
              // </Collapse>
            )}

            {(city == 'Islamabad' || city == 'Rawalpindi') && (
              <FormControl as="fieldset">
                <FormLabel as="legend">Type</FormLabel>
                <RadioGroup defaultValue="Itachi">
                  <HStack spacing="24px">
                    <Radio value="free">Pickup</Radio>
                    <Radio value="price">Delivery</Radio>
                  </HStack>
                </RadioGroup>
                {/* <FormHelperText>Select only if you're a fan.</FormHelperText> */}
              </FormControl>
            )}

            <FormControl id="email">
              <FormLabel>Name</FormLabel>
              <Input type="text" focusBorderColor="crimson" />
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            </FormControl>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" focusBorderColor="crimson" />
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            </FormControl>
            <FormControl id="email">
              <FormLabel>Phone number</FormLabel>
              <InputGroup>
                <InputLeftAddon children="+92" />
                <Input
                  type="number"
                  placeholder="phone number"
                  focusBorderColor="crimson"
                />
              </InputGroup>
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            </FormControl>
          </Stack>
          <Flex
            flex="1"
            mt={['0px', '50px']}
            ml={['0', '0', '70px', '70px', '70px']}
            justifyContent="center"
          >
            <Image src="/images/donate-book.svg" boxSize="400px" />
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default DonationForm
