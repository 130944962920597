import React from 'react'
import {
  Button,
  Slide,
  Box,
  useDisclosure,
  Container,
  IconButton,
  Input,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'

const SideNav = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const btnRef = React.useRef()
  return (
    <>
      <IconButton
        aria-label="Search database"
        icon={<HamburgerIcon w={6} h={6} />}
        ref={btnRef}
        bg="none"
        _hover={{ bg: 'none' }}
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={['full']}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>BookNeeded.</DrawerHeader>

          <DrawerBody>{/* <Input placeholder="Type here..." /> */}</DrawerBody>

          <DrawerFooter>
            {/* <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue">Save</Button> */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default SideNav
