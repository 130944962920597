import React from 'react'
import Navbar from '../components/Navbar'
import Banner from '../components/Banner'
import Workings from '../components/Workings'
import DonationForm from '../components/DonationForm'
import GetBookForm from '../components/GetBookForm'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <>
      <Navbar />
      <Banner />
      <Workings />
      <DonationForm />
      <GetBookForm />
      <Footer />
    </>
  )
}

export default Home
