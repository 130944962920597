import React, { useState } from 'react'
import {
  Flex,
  Box,
  Image,
  Text,
  Button,
  Center,
  Icon,
  Select,
  Stack,
  Container,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  RadioGroup,
  Radio,
  HStack,
  InputLeftAddon,
  InputGroup,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import { ArrowForwardIcon } from '@chakra-ui/icons'

const GetBookForm = () => {
  const { isOpen, onToggle } = useDisclosure()
  //   const [city, setCity] = useState('')

  return (
    <Box mb={['0px', '0px', '40px', '40px', '40px']}>
      <Container maxW="1200px">
        <Center mb={4}>
          <Text fontSize="25px" fontWeight="600" pb="15px">
            Get a Book
          </Text>
        </Center>
        <Flex
          width="90%"
          margin="0 auto"
          justifyContent="space-between"
          flexDirection={[
            'column-reverse',
            'column-reverse',
            'row',
            'row',
            'row',
          ]}
        >
          <Flex
            flex="1"
            mt={['0px', '50px']}
            mr={['0', '0', '70px', '70px', '70px']}
            justifyContent="center"
          >
            <Image src="/images/get-book.svg" boxSize="400px" />
          </Flex>
          <Stack
            direction="column"
            // width={['100%', '80%', '60%', '40%']}
            // margin="0 auto"
            flex="0.9"
            boxShadow="xl"
            px={5}
            py={10}
            rounded="lg"
          >
            <FormControl id="category">
              <FormLabel>Select Category</FormLabel>
              <Select
                placeholder="Select category"
                focusBorderColor="crimson"
                //   onChange={e => {
                //     setCity(e.target.value)

                //   }}
              >
                <option>Leadership</option>
                <option>Business</option>
                <option>Fantasy</option>
              </Select>
            </FormControl>

            <FormControl as="fieldset">
              <FormLabel as="legend">Select</FormLabel>
              <RadioGroup defaultValue="Itachi">
                <HStack spacing="24px">
                  <Radio value="free">Free</Radio>
                  <Radio value="paid">Paid</Radio>
                </HStack>
              </RadioGroup>
              {/* <FormHelperText>Select only if you're a fan.</FormHelperText> */}
            </FormControl>

            <FormControl id="category">
              <FormLabel>Select Book</FormLabel>
              <Select
                placeholder="Select category"
                focusBorderColor="crimson"
                //   onChange={e => {
                //     setCity(e.target.value)

                //   }}
              >
                <option>Compound Effect</option>
                <option>Innercise</option>
                <option>Power of Now</option>
              </Select>
            </FormControl>

            <FormControl as="fieldset">
              <FormLabel as="legend">Type</FormLabel>
              <RadioGroup defaultValue="Itachi">
                <HStack spacing="24px">
                  <Radio value="free">Pickup</Radio>
                  <Radio value="price">Delivery</Radio>
                </HStack>
              </RadioGroup>
              {/* <FormHelperText>Select only if you're a fan.</FormHelperText> */}
            </FormControl>

            <FormControl id="email">
              <FormLabel>Delivery Address</FormLabel>
              <Input type="text" focusBorderColor="crimson" />
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            </FormControl>
          </Stack>
        </Flex>
      </Container>
    </Box>
  )
}

export default GetBookForm
