import styled from 'styled-components'
import customTheme from '../@chakra-ui/gatsby-plugin/theme'

export const UnderlinedHeading = styled.div`
  position: relative;
  padding-bottom: 15px;
  display: ${props => (props.center ? 'block' : 'inline-block')};
  &:after {
    content: '';
    position: absolute;
    width: 80px;
    height: 3px;
    background: 'crimson';
    left: 0;
    bottom: 0;
    right: ${props => (props.center ? 0 : 'initial')};
    margin: ${props => (props.center ? '0 auto' : 'initial')};
  }
`
