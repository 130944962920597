import React from 'react'
import {
  Flex,
  Box,
  Image,
  Text,
  Button,
  Center,
  Icon,
  Stack,
  Container,
  Heading,
} from '@chakra-ui/react'
import { Link } from 'gatsby'

import { ArrowForwardIcon, StarIcon } from '@chakra-ui/icons'
import { UnderlinedHeading } from '../../src/styles/styledComponents'

const Banner = () => {
  return (
    <Box mb="80px" mt={['20px', '50px', '100px', '0px', '0px']}>
      <Center mb={4}>
        <Text fontSize="25px" fontWeight="600" pb="15px">
          How it works
        </Text>
        {/* <UnderlinedHeading>
          <Heading size="lg">How it works</Heading>
        </UnderlinedHeading> */}
      </Center>
      <Container maxW="1200px">
        <Flex direction={['column', 'column', 'row', 'row']}>
          <Stack direction="column" spacing={4}>
            <Icon as={StarIcon} w={6} h={6} color="red.500" />
            <Text fontWeight="600">Find Books</Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
              dolorem nostrum incidunt aspernatur unde, ea, rem pr
            </Text>
          </Stack>
          <Stack direction="column" spacing={4} my={[2, 2, 2, 'auto', 'auto']}>
            <Icon as={StarIcon} w={6} h={6} color="red.500" />
            <Text fontWeight="600">Find Books</Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
              dolorem nostrum incidunt aspernatur unde, ea, rem pr
            </Text>
          </Stack>
          <Stack direction="column" spacing={4}>
            <Icon as={StarIcon} w={6} h={6} color="red.500" />
            <Text fontWeight="600">Find Books</Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
              dolorem nostrum incidunt aspernatur unde, ea, rem pr
            </Text>
          </Stack>
        </Flex>
      </Container>
    </Box>
  )
}

export default Banner
